@import '../../../scss/vars';

.chips-panel {
  padding-bottom: 5px;

  .clear-filters-button {
    padding: 0 10px;
    color: $dodger-blue;
    font-size: 12px;
    letter-spacing: 0.11px;
    cursor: pointer;
    white-space: nowrap;
  }
}
