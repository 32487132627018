@import "../../../scss/vars";

.contentWrapper {
  position: absolute;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    padding: 1rem 2rem;
  }

  .alignerItemFixed {
    flex: none;
    max-width: 50%;
  }

}