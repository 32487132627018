@import '../../../scss/vars';

.filter_panel {
  display: inline-block;
  margin-left: 2rem;
}

:global {
  .zero-height-filter {
    height: 0;
  }
}

.single-date-header-filter {
  :global {
    .SingleDatePicker .SingleDatePickerInput {
      background: none;
      .DateInput {
        width: 13.5rem;
        background: none;
        input.DateInput_input {
          padding: 0 56px 0 2px;
          font-size: 1.5rem;
          color: $malibu-dark;
          background: none;
        }
      }

      .SingleDatePickerInput_calendarIcon {
        bottom: 3px;
        right: 6px;
        img {
          width: 22px;
        }
      }
    }
  }
}