@import '../../../scss/vars';

.chip {
  display: inline-block;
  position: relative;
  border: 1px solid $dodger-blue;
  margin-right: 15px;
  padding: 3px 32px 0 12px;
  border-radius: 100px;
  line-height: 27px;
  &.disabled {
    opacity: 0.65;
    cursor: default;
    .close-icon {
      cursor: default;
    }
  }
  .chip-value {
    color: $havelock-blue;
    font-size: 0.75rem;
    letter-spacing: -0.15px;
  }
  .close-icon {
    position: absolute;
    top: calc(50% - 9px);
    right: 5px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    background-color: $dodger-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid $dodger-blue;
    cursor: pointer;
  }

  .close-icon::before,
  .close-icon::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: $white;
    border-radius: 0.5px;
  }
  .close-icon::before {
    transform: rotate(45deg);
  }
  .close-icon::after {
    transform: rotate(-45deg);
  }
}