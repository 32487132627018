@import "../../../../scss/vars";
.button_danger {
  background-color:$btn-danger;
}

.button_danger_inverse {
  background-color: transparent;
  color:$btn-danger;
  border: 1px solid $btn-danger;
  font-weight: $font-weight-bold;
  &:hover:not(:disabled):not(.disabled){
    background-color: $btn-danger;
    border-color: $btn-danger;
    color: white;
    opacity: 1;
  }
}