@import '../../../scss/_vars.scss';

.fixedWrapper {
  position: fixed;
  bottom: 0;
  width: 60%;
  left: 20%;
  z-index: 99;
  :global {
    div {
      div.progressBlock {
        border-bottom: 1px solid $gray-chateau;
      }
      &:first-child {
        div.progressBlock > div {
          border-radius: 6px 6px 0 0;
        }
      }
      &:last-child {
        div.progressBlock {
          border-bottom: none;
        }
      }
    }
  }
}
