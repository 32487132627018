@import "../../../scss/vars";

.selected {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0.3125rem;
    background-color: $blue;
  }
}

.menu {
  height: 4rem;
  min-height: 4rem;
  padding-left: 2rem;
  background-color: $white;
  display: flex;

  .item, .item-disabled {
    vertical-align: middle;
    padding-right: 1rem;
    padding-left: 1rem;
    .title {
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: $font-weight-bold;
      color: $dark-gray;
      letter-spacing: 0;
    }
  }
  .item:hover {
    background-color: $super-light-blue;
    text-decoration: none;
  }
  .item:not(.selected) .title {
    color: $dodger-blue;
  }
  .item-disabled .title {
    color: $dusty-gray;
    cursor: default;
  }
  .settings {
    flex: 2;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
  }
}
