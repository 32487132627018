@import "../../../scss/vars";

.selected {
  position: relative;
  &:after {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 5px;
    background-color: $blue;
  }
}

.navButton {
  padding: 8px 8px 8px (($panel-width - $panel-image-height) / 2 - 10);
  min-height: 56px;
  line-height: normal;
  .icon {
    padding: 5px 0;
    margin-right: 16px;
    span {
      font-size: $panel-image-height;
      padding: 0 8px;
      display: inline-block;
    }
  }
  .image {
    margin-right: 16px;
    img {
      height: $panel-image-height;
    }
  }
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  text-align: center;
  div {
    padding: 10px 0;
  }
  &:hover {
    background-color: $super-light-blue;
    text-decoration: none;
  }
}

.navButton.selected {
  color: $dark-gray;
}

.navButton:not(.selected) {
  color: $dodger-blue;
}

.navButton:first-child {
  margin-top: 75px;
}