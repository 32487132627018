@import '../../../scss/vars';

.dataTableHeader {
  width: 100%;
  .fixed-header {
    &.last-fixed-column {
      border-right: 0.1rem solid $dark-gray-transparent;
    }
  }

  .checkbox-fixed-header label {
    top: 6px;
  }

  .fixed-style {
    position: absolute;
    top: auto;
    left: 0;
    z-index: 9;
    background-color: $porcelain;
  }

  .hide-column {
    visibility: hidden;
  }

  .bulk-action-link {
    font-size: 0.75rem;
    cursor: pointer;
  }
}
