@import "../../scss/vars";

.resetFormWrapper {
  border: solid 1px $blue;
  padding: 10px;
  border-radius: 10px;
  form {
    display: flex;
    flex-direction: column;
  }
}