@import '../../../scss/vars';

.ui_input {
  display: block;
  height: auto;
  width: 100%;
  font-weight: $font-weight-regular;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.006875rem;
  color: $trout;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $catskill-white;
  border-radius: .25rem;
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  padding-left: 20px;

  &:focus {
    color: $trout;
    background-color: $white;
    border-color: $malibu;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}

.ui_input_material {
  display: block;
  width: 100%;
  float:left;
  margin-top: 1.25rem;
  border: none;
  padding: 0;
  border-bottom: solid 1px $alto;
  font-size: 0.875rem;
  color: $dove-gray;
  border-radius: 0;
  transition: all 0.3s cubic-bezier(.64, .09, .08, 1);
  background-position: -200px 0;
  background-size: 100%;
  background-repeat: no-repeat;

  :global {
    :-ms-input-placeholder {
      color: $alto;
      font-size: 0.875rem;
    }
  }
  &::placeholder {
    color: $alto;
    font-size: 0.875rem;
  }

  /* Internet Explorer 10-11 */
  &:-ms-input-placeholder {
    color: $alto;
  }

  &:focus {
    box-shadow: none;
    outline: none;
    border-bottom: solid 1px $blue;
    background-position: 0 0;
    &::-webkit-input-placeholder {
      color: $alto;
      visibility: visible !important;
    }
  }

  & + .label {
    color: $dusty-gray;
    font-size: 0.625rem;
    font-weight: $font-weight-medium;
    margin-bottom: 0;
    transform: translate(0, -3rem);
  }

  &:focus + .label {
    color: $dodger-blue;
  }
}