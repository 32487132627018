@import "../../../../scss/vars";
.ui_different_row {
  :global {
    table tbody tr:nth-of-type(odd){
      background-color: $white;
    }
  }
}

.ui_different_row_inverse {
  :global {
    table tbody tr:nth-of-type(odd) {
      background-color: $aqua-haze;
    }
  }
}