@import '../../../scss/vars';

.date_picker {

  :global {
    // reset bootstrap styles not to be applied to nested table
    table.CalendarMonth_table {
      > tbody {
        td {
          padding: 0;
        }
        tr:hover {
          background: none;
        }
        td:not(.CalendarDay) {
          border-top: none;
        }
      }
    }

    .DateInput, .DatePicker {
      display:block;
      padding:0;
    }
    .DateInput_input {
      border-bottom: none;
      font-weight: normal;
      letter-spacing: 0.13px;
    }

    .SingleDatePickerInput__withBorder {
      border: none;
    }
    .DateInput {
      width: 100%;
      input.DateInput_input {
        line-height: normal;
      }
      input::-ms-clear {
        display: none;
      }
    }

    .CalendarDay__selected_span {
      background-color: $light-blue;
    }

    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__hovered_span,
    .CalendarDay__hovered_span:hover
    {
      background: $blue;
      border: 1px solid $blue;
      color: $white;
    }

    .CalendarDay__today {
      background-color: $light-blue;
      color: $white;
    }

    .SingleDatePickerInput_clearDate {
      padding: 2px;
      line-height: 0;
      right: 1.5rem;
      top: calc(50% - 1px);
    }
    .SingleDatePickerInput_clearDate_svg {
      height: 12px;
      width: 12px;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight {
        &:focus {
          outline: none;
        }
        &:before {
          border-top: 26px solid transparent;
          border-right: 33px solid $blue;

          &:hover {
            background: $blue;
          }
        }
    }
  }
}

.date_picker_material {
  :global {
    .SingleDatePicker {
      display: block;
      .SingleDatePickerInput {
        :focus {
          outline: none;
        }
        width: 100%;
        position: relative;
        &__withBorder {
          border: none;
        }
        &__showClearDate {
          padding-right: 0;
        }
        &_calendarIcon {
          padding: 0;
          margin: 0;
          position: absolute;
          right: 2px;
          z-index: 1;
          line-height: normal;
        }

        .DateInput {
          &_input {
            border-bottom: 1px solid $alto;
            &::-webkit-input-placeholder {
              color: transparent;
            }
            text-align: left;
            &__focused {
              border-bottom: 1px solid $light-blue;
              background: none;
            }
          }
        }
      }
      .SingleDatePicker_picker {
        z-index: 10;
      }
    }
  }
}
