@import '../../../scss/vars';

.dataset-status {
  margin: 0 0.25rem;
  font-size: 0.75rem;
  letter-spacing: 0;
  color: $dark-gray;
}
.dataset-period {
  line-height: 1rem;
  font-size: 0.625rem;
  letter-spacing: 0.005625rem;
  color: $white;
  margin-right: 0.25rem;
  width: 2rem;
  height: 1rem;
  display: inline-block;
  text-align: center;
}

// for status cell, color of status text
.dataset-status.ready_to_publish,
.dataset-status.scheduled,
.dataset-status.scheduled_partially {
  color: $yellow;
}
.dataset-status.published,
.dataset-status.published_partially {
  color: $green;
}
.dataset-status.not_yet_started {
  color: $dusty-gray;
}
.dataset-status.past_due {
  color: $red;
}

// for status cell, color of rectangles
.in_progress-current-period {
   background-color: $dodger-blue;
}
.ready_to_publish-current-period,
.scheduled-current-period,
.scheduled_partially-current-period {
  background-color: $yellow;
}
.published-current-period,
.published_partially-current-period {
  background-color: $green;
}
.not_yet_started-current-period {
  background-color: $aqua-haze;
}
.past_due-current-period {
  background-color: $red;
}

// for fund status
.on_time {
  color: $jungle-green
}
.on_time-current-period {
  background-color: $jungle-green;
}
.no_request {
  color: $boulder;
}
.no_request-current-period {
  background-color: $boulder;
}
.late {
  color: $yellow;
}
.late-current-period {
  background-color: $yellow;
}
.overdue {
  color: $alizarin-crimson;
}
.overdue-current-period {
  background-color: $alizarin-crimson;
}
.partial {
  color: $froly;
}
.partial-current-period {
  background-color: $froly;
}
.awaiting_data {
  color: $timberwolf;
}
.awaiting_data-current-period {
  background-color: $timberwolf;
}

// for history cell, color of rectangles
.green-history {
  background-color: $green;
}
.grey-history {
  background-color: $aqua-haze;
}
.dark-grey-history {
  background-color: $gray-dark;
}
.red-history {
  background-color: $red;
}

// for fund reporting history
.jungle-green-history {
  background-color: $jungle-green;
}
.boulder-history {
  background-color: $boulder;
}
.yellow-history {
  background-color: $yellow;
}
.alizarin-crimson-history {
  background-color: $alizarin-crimson;
}
.froly-history {
  background-color: $froly;
}
.timberwolf-history {
  background-color: $timberwolf;
}
