@import "../../../../scss/vars";
.results {
  .search_result {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    font-size: 0.8em;
    border-bottom: 1px solid $black-shadow;
    cursor: pointer;
    &:last-child {
      border: none;
      &:hover {
        border-radius: 0 0 5px 5px;
      }
    }
    &:first-child {
      &:hover {
        border-radius: 5px 5px 0 0;
      }
    }
    &:hover {
      background-color: $dodger-blue;
      color: $white;
    }

    .type {
      color: $search-attribute-type;
      text-transform: capitalize;
    }
  }
}