@import "../../../../scss/vars";
@import "../../../../scss/mixins";

.ui_data_table {
  position:relative;
  :global {
    table.ap-data-table {
      border-collapse: collapse;
      background: $porcelain;
      margin-bottom: 0;
      border-color: white;
      // commented because Select doesn't visible fully
      //overflow: hidden;
      font-size: 0.75rem;
      letter-spacing: 0.006875rem;
      line-height: 1.25rem;
      color: $limed-spruce;
      table-layout: fixed;
      width: 100%;
      @include bp($tablet-max, 'max') {
        font-size: 0.6875rem;
        line-height: 1rem;
      }
      @include bp($tablet-s-max, 'max') {
        font-size: 0.625rem;
        line-height: 0.8rem;
      }

      > td, > th {
        padding: 1rem 0.5rem 1rem 1rem;
      }

      > tbody {
        col {
          &.checkboxHeader {
            width: 3rem;
          }
          &.action-cell {
            @include bp($tablet-max, 'max') {
              width: 6rem;
            }
            width: 6.75rem;
          }
        }
        .highlited {
          background-color: $half-spanish-white;

          > td {
            background-color: $half-spanish-white;
          }
        }
        .hoverable {
          .show-on-row-hover {
            display: none;
            @media ('hover': none) {
              display: flex;
            }
          }
          &:hover {
            .show-on-row-hover {
              display: flex;
            }
          }
        }
        .removed > td {
          color: $red-semi-transparent;
        }
        .removed :hover > td {
          color: $red;
        }
        .translucent {
          > td {
            opacity: 0.5;
          }
          &:hover {
            > td {
              opacity: 1;
            }
          }
        }
        .translucent:hover {
          > td {
            opacity: 1;
          }
        }

        > tr {
          > td {
            vertical-align: middle;
            background-color: white;
            border-top: 0.0625rem solid $gallery;
            &:first-child {
              &:not(.checkboxHeader) {
                padding-left: 1rem;
              }
            }
            &.checkboxHeader {
              @include bp($tablet-max, 'max') {
                padding: 1rem 0 1rem 1.25rem;
              }
              width: 2rem;
              padding-left: 1.25rem;
              padding-right: 0;
              vertical-align: middle;
            }
            &.action-cell {
              @include bp($tablet-max, 'max') {
                width: 6rem;
              }
              text-align: center;
              width: 6.75rem;
              img {
                margin: 0.25rem;
              }
            }
          }
          &.checkboxHeader {
            > td {
              border-top: none;
              &.checkboxHeader {
                border-radius: 0.375rem 0 0 0;
              }
              &:last-child {
                border-radius: 0 0.375rem 0 0;
              }
            }
          }
        }
      }
      > tbody:first-child, > colgroup + tbody {
        > tr {
          &:first-child {
            > td {
              border: none;
            }
          }
        }
      }

      > thead {
        > tr {
          > th {
            height: 2.5rem;
            color: $limed-spruce-transparent;
            font-size: 0.625rem;
            letter-spacing: 0;
            line-height: 0.75rem;
            font-weight: $font-weight-medium;
            border: none;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            cursor: default;
            &.with-sorting {
              cursor: pointer;
            }
            &:first-child {
              &:not(.checkboxHeader) {
                padding-left: 1rem;
              }
            }
            &.checkboxHeader {
              @include bp($tablet-max, 'max') {
                padding: 0.5rem 0 0.5rem 1.25rem;
              }
              width: 2rem;
              padding-left: 1.25rem;
              padding-right: 0;
              vertical-align: middle;
            }
            &.action-cell{
              @include bp($tablet-max, 'max') {
                width: 6rem;
              }
              text-align: center;
              width: 6.75rem;
            }
          }
          > th.action-color {
            float: right;
            margin-right: 1.5rem;
            padding-top: 0.75rem;
          }
        }
      }

      > thead > tr.fixedRow > th.checkboxHeader {
        padding-right: 0;
        width: 2.65rem;
      }
      > thead.is_action_bulk_checked {
        background-color: $blue-transparent-bulk-action-checked;
        > tr > th {
          &.checkboxHeader {
            font-size: 0.8125rem;
          }
        }
      }
    }

    img {
      cursor: pointer;
    }
  }
}

