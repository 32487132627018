@import '../../../scss/vars';

.toggle-requestors {
  font-size: 0.75rem;
  color: $dodger-blue;
  letter-spacing: 0.0075rem;
  text-align: right;
  padding-top: 0.5rem;
  cursor: pointer;
}

.label {
  font-size: 0.625rem;
  color: $limed-spruce-transparent;
  letter-spacing: 0;
}

.checkbox-label {
  font-size: 0.75rem;
  letter-spacing: 0.006875rem;
  color: $dark-gray;
  width: 24rem;
}

.checkbox-label.checked {
  color: $dodger-blue
}

.checkbox-row {
  line-height: 1rem;
  margin-bottom: 0.625rem;
}

.input-number {
  width: 7rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.select {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.ul-list {
  text-align: left;
  padding-left: 1rem;
}

.loader {
  position: absolute;
  top: 43%;
  left: 46%;
}