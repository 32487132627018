@import "../../../../scss/vars";

:global {
  .ui-btn:local(.button_primary) {
    font-weight: $font-weight-bold;
    background-color: $btn-primary;
    border: 0.0625rem solid $btn-primary;

    &:disabled,
    &.disabled {
      opacity: 0.5;
    }

    &:not(:disabled):not(.disabled):active {
      border: 0.0625rem solid $btn-primary;
      background-color: $btn-primary;
    }

    &:local(.button_primary_inverse) {
      &:not(:disabled):not(.disabled):not(:hover) {
        background-color: transparent;
        color: $btn-primary;
      }
    }

    &.red:local(.button_primary_inverse) {
      &:not(:disabled):not(.disabled):not(:hover) {
        color: $btn-danger;
      }

      &:hover {
        background-color: $btn-danger;
        color: $white;
      }

      border-color: $btn-danger;
    }
  }
}