@import "../../../scss/vars";

.date_range_picker {

  :global {
    table.CalendarMonth_table {
      table-layout: auto;

      td {
        padding:0;

        &:last-child {
          width:auto;
        }
      }

    }
    .DateRangePickerInput, .DateRangePicker{
      display:block;
      padding:0;
    }
    .DateInput_input {
      padding: 4px;
      font-size:15px;
      text-align:center;
    }

    .DateRangePickerInput__withBorder {
      border-radius: 5px;
      border-color: $catskill-white;
    }
    .DateInput{
      width:47%;
      margin-left:2px;
    }
    input.DateInput {
      height:34px;
    }

    .CalendarDay__selected_span{
      background-color: $light-blue;
    }

    .DateInput_input__focused {
      border-bottom: 2px solid $light-blue;
    }

    .CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__hovered_span, .CalendarDay__hovered_span:hover{
      background: $blue;
      border: 1px solid $blue;
      color: $white;
    }

    .CalendarDay__today{
      background-color: $light-blue;
      color: $white;
    }

    .DateRangePickerInput_clearDates{
      padding: 0;
      right: -4px;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight:hover{
      background: $blue;
    }

    .DayPickerKeyboardShortcuts_show__bottomRight {
      border-top: 26px solid transparent;
      border-right: 33px solid $blue;
    }


  }
}
