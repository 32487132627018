@import "../../../../scss/vars";

.dropdown-icon {
  :global {
    .dropdown-menu {
      padding: 0;
      .dropdown-item {
        font-size: 12px;
        padding: 0.5rem 1.5rem;
        cursor: pointer;
        &:focus {
          outline: none;
        }
        &:hover {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
}
