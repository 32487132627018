@import "../../scss/vars";

$border-color: $alto;

.userButton {
  float: right;
  margin: 15px 15px 15px 0;

  :global {

    button.btn-secondary.btn {
      font-size: 12px;
      letter-spacing: 0.1px;
      color: $dodger-blue;
      background-color: transparent;
      border: none;
      &:hover, &:active, &:focus, &:visited {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    .dropdown-menu {
      top: 36px !important;
      right: 0 !important;
      left: auto !important;
      transform: none!important;
      width: 280px;
      border-radius: 6px;
      button {
        &:hover {
          cursor: pointer;
          background-color: gainsboro;
        }
      }
    }

    .dropdown-item.active, .dropdown-item:active {
      background-color: $blue;
      &:hover {
        background-color: $blue;
      }
    }

    i {
      border: solid $dodger-blue;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      margin: 3px 9px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}

.dropHeader {
  padding: 25px;
  border-bottom: 1px solid $border-color;
  .info {
    font-size: 12px;
    color: $dusty-gray;
  }
  .user {
    font-size: 13px;
    margin-top: 15px;
    text-transform: uppercase;
    &.email {
      text-transform: none;
    }
  }
}

.dropBody {
  padding: 15px 25px;
  border-bottom: 1px solid $border-color;
  :global {
    button, a {
      padding: 10px 0;
      color: $dodger-blue;
      text-transform: uppercase;
      font-size: 13px;
      &:hover {
        background-color: transparent !important;
        color: $dodger-blue;
        text-decoration: underline;
      }
    }
  }
}

.dropAccounts {
  border-bottom: 1px solid $border-color;
  padding: 15px 0;
  :global {
    button, a {
      color: $dodger-blue;
      text-transform: uppercase;
      font-size: 13px;
      padding: 10px 25px;
      &:not(.active):hover {
        background-color: transparent !important;
        color: $dodger-blue;
        text-decoration: underline;
      }
    }
  }
}

.dropFooter {
  padding: 15px 25px 10px;
  :global {
    button, a {
      padding: 10px 0;
      color: $dodger-blue;
      text-transform: uppercase;
      font-size: 13px;
      &:hover {
        background-color: transparent !important;
        color: $dodger-blue;
        text-decoration: underline;
      }
    }
  }
}
