@import "../../../scss/vars";

.uploadWrapper {
  padding: 70px 48px 48px;
  border-radius: 6px;
  background-color: $aqua-haze;
  position: relative;
}

.uploadProcessingMessage {
  padding: 25px;
  background-color: $uploadMessage;
  border-radius: 6px;
  border: 1px solid $uploadMessageBorder;
  color: $dodger-blue;
  position: relative;
  :global {
    div {
      &:first-child {
        font-size: 18px;
        padding-bottom: 8px;
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
}

.uploadFailMessage {
  margin-bottom: 40px;
  padding: 25px;
  background-color: $dodger-red;
  border-radius: 6px;
  border: 1px solid $red-border;
  color: $red;
  position: relative;
  :global {
    div {
      &:first-child {
        font-size: 18px;
        padding-bottom: 8px;
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
}

.uploadSuccessMessage {
  margin-bottom: 40px;
  padding: 25px;
  background-color: $week-green;
  border-radius: 6px;
  border: 1px solid $import-green;
  color: $green;
  position: relative;
  :global {
    div {
      &:first-child {
        font-size: 18px;
        padding-bottom: 8px;
      }
      &:last-child {
        font-size: 12px;
      }
    }
  }
}

.mainCloser {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.lilCloser {
  position: absolute;
  right: 32px;
  top: 20px;
  cursor: pointer;
  width: 16px;
}

.cancelUpload {
  color: $dodger-blue;
  font-size: 12px;
  cursor: pointer;
  padding-left: 6px;
}

.progressWrapper {
  margin: 40px 0 20px;
}

.uploadHeader {
  color: $dark-gray;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: normal;
  margin: 35px 0 5px;
}

.dragdropWrapper {
  padding: 0 0 40px;
}