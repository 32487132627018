@import "../../../scss/vars";

.dataset_row {
  border-top: none !important;
  :global {
    tbody {
      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 6px;
          }
        }
      }
      td {
        border-top: 0.0625rem solid $river-bed;
      }
    }
    .triangledBlueTd {
      height: 64px;
    }
  }
}

.tr {
  :global {
    td {
      background-color: $super-dark-blue !important;
      border-top: 0.0625rem solid $river-bed !important;
      color: white;
      position: relative;
    }
  }
}
