@import "../../../scss/vars";

.tableGridHeader {
  margin-bottom: 0;
  margin-top: 15px;
  border: none;
}

.scroller {
  //height: 350px;

  // commented because Select doesn't visible fully
  //overflow-y: auto;
}

.scroll_block {
  width: 100%;
  height: 80px;
  overflow:auto;
}

:global {
  .fake-header {
    overflow-x: hidden;

    &.invisible {
      display: none;
    }

    &.fake-header-fixed {
      overflow-x: visible;
      position: relative;
    }
  }
}

.loader {
  float: right;
  margin-top: 5px;
  margin-right: 2px;
  position:absolute;
  right:0;
}
.loader_in_table {
  display:inline-block;
}

table.tableGrid {
  table-layout: fixed;

  &.scrollableHeader {
    table-layout: auto;
  }

  .lastFixedColumn {
    border-right: 0.1rem solid $dark-gray-transparent;
  }

  :global {

    th.bulkActions {
      text-align: right;

      .text-primary {
        margin-left: 15px;
      }

      .items-selected {
        display: inline-block;
        float: left;
        margin-left: 0;
      }
    }

    thead > tr, tbody > tr {
      th.primary, td.primary {
        color:$white;
        background-color: $blue;
        border-top: none;
      }
      th.primary {
        background-color: $dodger-blue-deep;
        border-radius: 6px 0 0 0;
      }
      .w-min {
        width: 70px;
      }
      td.w-min {
        padding: 0 3px 0 0 !important;
      }
      th.w-min {
        padding: 0 !important;
      }
    }

    tbody.j-body_items{
      &:last-child{
        .primary{
          border-radius: 0 0 0 6px;
        }
      }
    }

    /* sorting block */

    thead > tr.hasSorting > th.with-sorting {
      padding-right: 16px;
    }

    thead > tr.hasSorting > th.with-sorting > span {
      cursor: pointer;
      position: relative;
      display: inline-block;
    }

    thead > tr.hasSorting > th.with-sorting > span:after {
      content: ' ';
      position: absolute;
      display: block;
      height: 0;
      width: 0;
      right: -15px;
      top: 2px;
    }

    thead > tr.hasSorting > th.with-sorting > span:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $silver;
      border-bottom: 0 solid transparent;
    }

    thead > tr.hasSorting > th.with-sorting > span:hover:after {
      border-top: 5px solid $gray;
    }

    thead > tr.hasSorting > th.with-sorting.desc > span:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 0 solid transparent;
      border-bottom: 5px solid $mine-shaft-light;
    }

    thead > tr.hasSorting > th.with-sorting.desc > span:hover:after {
      border-bottom: 5px solid $gray;
    }

    thead > tr.hasSorting > th.with-sorting.asc > span:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid $mine-shaft-light;
      border-bottom: 5px solid transparent;
    }

    .action-items a {
      margin-right:10px;
    }
  }
}

.tableIcon {
  margin: -0.25rem 0 0 0.625rem;
  &.disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.actionGroup {
  display: flex;
  justify-content: flex-start;
  .action {
    display: inline-block;
    padding-right: 10px;
  }
}

.editableInputCell {
  font-size: 0.75rem;
  padding: 0.25rem 0.625rem;
}

.test {
  border-radius: 6px 6px 0 0;
}

:global {
  .hidden-action {
    display: none;
  }
  .invisible-action {
    visibility: hidden;
  }
  .table-responsive {
    overflow-x: hidden;
  }
}

.no-data-with-fixed-columns {
  position: absolute;
  top: auto;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.header-on-top {
  z-index: 100!important;
}
