@import "../../../scss/vars";

.date_filter {

  :global {
    .DateRangePickerInput, .DateRangePicker {
      display: block;
      padding: 0;
    }
    .DateRangePickerInput__withBorder {
      border-radius: 5px;
    }
    .DateInput {
      width: 60%;
    }

    input.DateInput_input {
      padding: 0 32px 0 0;
      font-size: 14px;
    }

    .SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
      bottom: 3px;
      img {
        width: 16px;
      }
    }
  }
}
