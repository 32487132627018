@import "../../../../scss/vars";

$flex-width: calc(100% / 7);

.date_filter {
  overflow: hidden;
}

.input {
  border: none;
  border-bottom: 1px solid $black-shadow;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.9em;
  border-radius: 0;
  &:global(.form-control) {
    &:focus {
      box-shadow: none;
    }
  }
  &[readonly] {
    background-color: $white;
  }
}

.calendar_wrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: $white;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100%;
  // theme
  color: $black;
  font-size: 12px;

  :global {
    .rdrDateDisplay{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .rdrDateDisplayItem{
      -webkit-box-flex: 1;
      -ms-flex: 1 1;
      flex: 1 1;
      width: 0;
      text-align: center;
      color: inherit;
    }

    .rdrDateDisplayItem + .rdrDateDisplayItem{
      margin-left: 0.833em;
    }

    .rdrDateDisplayItem input{
      text-align: inherit;
    }

    .rdrDateDisplayItem input:disabled {
      cursor: default;
    }

    .rdrDateDisplayItemActive{}

    .rdrMonthAndYearWrapper {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    .rdrMonthAndYearPickers{
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .rdrMonthPicker{}

    .rdrYearPicker{}

    .rdrNextPrevButton {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
      cursor: pointer;
      outline: none;
    }

    .rdrPprevButton {}

    .rdrNextButton {}

    .rdrMonths{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .rdrMonthsVertical{
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .rdrMonthsHorizontal > div > div > div{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
    }

    .rdrWeekDays{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .rdrWeekDay {
      -ms-flex-preferred-size: $flex-width;
      flex-basis: $flex-width;
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
      text-align: center;
    }

    .rdrDays{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .rdrDateDisplayWrapper{}

    .rdrMonthName{}

    .rdrInfiniteMonths{
      overflow: auto;
    }

    .rdrDateRangeWrapper{
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .rdrDay {
      -webkit-box-sizing: inherit;
      box-sizing: inherit;
      width: $flex-width;
      position: relative;
      font: inherit;
      cursor: pointer;
    }

    .rdrDayNumber {
      display: block;
      position: relative;
      color: $black;
    }

    .rdrDayNumber span{
      color: $date-day-number;
    }

    .rdrDayDisabled {
      cursor: not-allowed;
    }

    .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
      pointer-events: none;
    }

    .rdrSelected {
      display: block;
      background-color: $blue;
      width: 100%;
      height: 100%;
    }

    .rdrInRange{}

    .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
      pointer-events: none;
    }

    .rdrDayHovered{}

    .rdrDayActive{}

    .rdrDateRangePickerWrapper{
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .rdrDefinedRangesWrapper{}

    .rdrStaticRanges{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .rdrStaticRange{
      font-size: inherit;
    }

    .rdrStaticRangeLabel{}

    .rdrInputRanges{}

    .rdrInputRange{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    .rdrInputRangeInput{}

    // THEME

    .rdrDateDisplay{
      background-color: $date-display-background-color;
      padding: 0.833em;
    }

    .rdrDateDisplayItem{
      border-radius: 4px;
      background-color: $white;
      -webkit-box-shadow: 0 1px 2px 0 $date-display-item-shadow;
      box-shadow: 0 1px 2px 0 $date-display-item-shadow;
      border: 1px solid transparent;
    }

    .rdrDateDisplayItem input{
      cursor: pointer;
      height: 2.5em;
      line-height: 2.5em;
      border: 0;
      background: transparent;
      width: 100%;
      color: $date-display-item;
    }

    .rdrDateDisplayItemActive{
      border-color: currentColor;
    }

    .rdrDateDisplayItemActive input{
      color: $date-display-item-active
    }

    .rdrMonthAndYearWrapper {
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 60px;
      padding-top: 10px;
    }

    .rdrMonthAndYearPickers{
      font-weight: 600;
    }

    .rdrMonthAndYearPickers select{
      -moz-appearance: none;
      appearance: none;
      -webkit-appearance: none;
      border: 0;
      background: transparent;
      padding: 7px 15px 3px 5px;
      border-radius: 4px;
      outline: 0;
      color: $date-year-picker-select;
      background: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='0.368716033'><g id='input' transform='translate(172.000000, 37.000000)' fill='#0E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow'></path></g></g></g></svg>") no-repeat;
      background-position: right 8px center;
      cursor: pointer;
      text-align: center
    }

    .rdrMonthAndYearPickers select:hover{
      background-color: $date-month-and-year-hover-background;
    }

    .rdrMonthPicker, .rdrYearPicker{
      margin: 0 5px
    }

    .rdrNextPrevButton {
      display: block;
      width: 24px;
      height: 24px;
      margin: 0 5px;
      padding: 0;
      border: 0;
      border-radius: 5px;
      background: $date-arrow-color;
    }

    .rdrNextPrevButton:hover{
      background: $date-arrow-hover-color;
    }

    .rdrNextPrevButton i {
      display: block;
      width: 0;
      height: 0;
      padding: 0;
      text-align: center;
      border-style: solid;
      margin: auto;
      -webkit-transform: translate(-3px, 0px);
      transform: translate(-3px, 0px);
    }

    .rdrPprevButton i {
      border-width: 4px 6px 4px 4px;
      border-color: transparent $date-arrow-border transparent transparent;
      -webkit-transform: translate(-3px, 0px);
      transform: translate(-3px, 0px);
    }

    .rdrNextButton i {
      border-width: 4px 4px 4px 6px;
      border-color: transparent transparent transparent $date-arrow-border;
      -webkit-transform: translate(3px, 0px);
      transform: translate(3px, 0px);
    }

    .rdrWeekDays {
      padding: 0 0.833em;
    }

    .rdrMonth{
      padding: 0 0 1.666em 0;
    }

    .rdrMonth .rdrWeekDays {
      padding: 0;
    }

    .rdrMonths.rdrMonthsVertical .rdrMonth:first-child .rdrMonthName{
      display: none;
    }

    .rdrWeekDay {
      font-weight: 400;
      line-height: 2.667em;
      color: $date-week-day;
    }

    .rdrDay {
      background: transparent;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid $zircon;
      margin: -0.5px;
      padding: 0;
      line-height: 3.000em;
      height: 3.000em;
      text-align: center;
      color: $date-day-number;

      &:hover {
        background-color: $zircon;
      }
    }

    .rdrDay:focus {
      outline: 0;
    }

    .rdrDayNumber {
      outline: 0;
      font-weight: 300;
      position: absolute;
      left: 0;
      right: 0;
      top: 5px;
      bottom: 5px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .rdrDayToday .rdrDayNumber span{
      font-weight: 500
    }

    .rdrDayToday {
      background-color: $light-blue;
      .rdrDayNumber span {
        color: $white;
      }
      &:hover {
        background-color: $light-blue;
      }
    }

    .rdrDayToday .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday .rdrSelected ~ .rdrDayNumber span:after{
      background: $white;
    }

    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span{
      color: $date-day;
    }

    .rdrInRange, .rdrStartEdge, .rdrEndEdge {
      background: currentColor;
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 5px;
    }

    .rdrInRange{}

    .rdrStartEdge{
      border-top-left-radius: 1.042em;
      border-bottom-left-radius: 1.042em;
      left: 2px;
    }

    .rdrEndEdge{
      border-top-right-radius: 1.042em;
      border-bottom-right-radius: 1.042em;
      right: 2px;
    }

    .rdrDefinedRangesWrapper{
      font-size: 12px;
      width: 226px;
      border-right: solid 1px $date-arrow-color;
      background: $white;
    }

    .rdrDefinedRangesWrapper .rdrStaticRangeSelected{
      color: currentColor;
      font-weight: 600;
    }

    .rdrStaticRange{
      border: 0;
      cursor: pointer;
      display: block;
      outline: 0;
      border-bottom: 1px solid $date-arrow-color;
      padding: 0;
      background: $white
    }

    .rdrStaticRange:hover .rdrStaticRangeLabel, .rdrStaticRange:focus .rdrStaticRangeLabel{
      background: $date-arrow-color;
    }

    .rdrStaticRangeLabel{
      display: block;
      outline: 0;
      line-height: 18px;
      padding: 10px 20px;
      text-align: left;
    }

    .rdrInputRanges{
      padding: 10px 0;
    }

    .rdrInputRange{
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 5px 20px;
    }

    .rdrInputRangeInput{
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      text-align: center;
      border: solid 1px $date-input-range-border;
      margin-right: 10px;
      color: $date-input-range;
    }

    .rdrInputRangeInput:focus, .rdrInputRangeInput:hover{
      border-color: $date-input-range-border-focus;
      outline: 0;
      color: $mine-shaft-light;
    }

    .rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after{
      content: '';
      border: 1px solid currentColor;
      border-radius: 1.333em;
      position: absolute;
      top: -2px;
      bottom: -2px;
      left: 0;
      right: 0;
      background: transparent;
    }

    .rdrDayPassive{
      pointer-events: none;
    }

    .rdrDayPassive .rdrDayNumber span{
      color: $date-day-number-passive;
    }

    .rdrDayPassive .rdrInRange, .rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrEndEdge, .rdrDayPassive .rdrSelected, .rdrDayPassive .rdrDayStartPreview, .rdrDayPassive .rdrDayInPreview, .rdrDayPassive .rdrDayEndPreview{
      // display: none;
    }

    .rdrDayDisabled {
      background-color: $date-day-disabled-background;
    }

    .rdrDayDisabled .rdrDayNumber span{
      color: $date-day-disabled-number;
    }

    .rdrDayDisabled .rdrInRange, .rdrDayDisabled .rdrStartEdge, .rdrDayDisabled .rdrEndEdge, .rdrDayDisabled .rdrSelected, .rdrDayDisabled .rdrDayStartPreview, .rdrDayDisabled .rdrDayInPreview, .rdrDayDisabled .rdrDayEndPreview{
      -webkit-filter: grayscale(100%) opacity(60%);
      filter: grayscale(100%) opacity(60%);
    }

    .rdrMonthName{
      text-align: left;
      font-weight: 600;
      color: $date-display-item;
      padding: 0.833em;
    }

  }
}

.hidden_calendar {
  display: none;
}
