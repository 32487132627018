@import "../../../scss/vars";

.font-icon {
  font-size: 24px;
  cursor: pointer;

  &:global(.icon-unlocked), &:global(.icon-discard) {
    color: $dodger-blue;
  }

  &:global(.icon-locked) {
    color: $yellow;
  }
}