@import "../../../../scss/vars";
.ui_main_header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 2rem;
  padding-bottom: 0.25rem;
  height: 5rem;
  background: $aqua-haze;
  border-bottom: 0.0625rem solid $gallery;
  font-size: 1.75rem;
  color: $dark-gray;
  letter-spacing: -0.05rem;
}

.header-title-and-filters {
   display: flex;
}

.header-title {
  display: flex;
  align-items: baseline;
}

$border-color: $alto;

.userButton {
  float: right;
  margin: 15px 15px 15px 0;

  :global {

    button.btn-secondary.btn {
      font-size: 14px;
      font-weight: $font-weight-bold;
      color: $dark-gray;
      letter-spacing: -0.52px;
      background-color: transparent;
      border: none;
      &:hover, &:active, &:focus, &:visited {
        background-color: transparent !important;
        border: none !important;
        box-shadow: none !important;
      }
    }

    .dropdown-menu {
      top: 36px !important;
      right: 0 !important;
      left: auto !important;
      transform: none!important;
      width: 280px;
      border-radius: 6px;
      button {
        &:hover {
          cursor: pointer;
          background-color: gainsboro;
        }
      }
    }

    .dropdown-item.active, .dropdown-item:active {
      background-color: $blue;
      &:hover {
        background-color: $blue;
      }
    }

    i {
      border: solid $dark-gray;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 3px;
      margin: 3px 9px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }
  }
}
