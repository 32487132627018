@import "../../../scss/vars";

.DropDownArea {
  margin-top: 12px;
  width: 100%;
  height: 250px;
  border: 2px dashed $mischka;
  border-radius: 6px;
  position: relative;
}

.DropDownAreaWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.DropDownAreaHeader {
  flex-wrap: wrap;
  display: block;
  font-size: 18px;
  color: $dark-gray;
  letter-spacing: -0.5px;
}

.DropDownAreaOr {
  display: block;
  color: $limed-spruce;
  letter-spacing: 0.1px;
  line-height: 1.67;
  font-size: 12px;
  margin-bottom: 0.5rem;
}
