@import "../../../scss/vars";

.logo {
  height: $header-height;
  min-height: $header-height;
  background-color: $dark-blue;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoWrapper {
  cursor: pointer;
}