@import "../../../scss/vars";

.wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .scrollButton {
    width: 2%;
    background: $delimiter-gray;
    display: flex;
    justify-content: center;
  }

  .headerScrollbar {
  }
}