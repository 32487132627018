@import "../../../scss/vars";

.contentWrapper {
  border-left: 0;
  flex: 1;
  .content {
    padding: 1.875rem 2rem 6.25rem 2rem;
    flex: 1;
    background-color: $aqua-haze;
    font-size: 0.75rem;
    color: $limed-spruce;
    letter-spacing: 0.006875rem;
    line-height: 1.25rem;
  }

}