@import '../../scss/vars';

$font_size: 15px;

.wrapper {
  font-size: $font-size;
  .textLine {
    padding-bottom: 25px;
    .checkboxWrapper {
      :global {
        span {
          min-width: 450px;
          font-size: $font-size;
        }
      }
    }
    a {
      color: $dodger-blue;
    }
  }
}