@import "../../../scss/vars";

.ui_text_color {
  display: inline-block;
  padding-right: 15px;

  :global {

    span {
      font-weight: $font-weight-medium;
      font-size: 13px;
    }
    .in_progress{
      color: $blue;
    }
    .ready_to_publish,
    .scheduled,
    .scheduled_partially {
      color: $yellow;
    }
    .published,
    .published_partially {
      color: $green;
    }
    .not_yet_started {
      color: $dusty-gray;
    }
    .past_due {
      color: $red;
    }
  }
}