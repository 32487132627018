@import '../../../../scss/vars';

.dropdown {
  display: none;
  background-color: $white;
  box-sizing: border-box;
  padding: 5px 0 5px 0;
  max-height: 300px;
  overflow: hidden;
}
.active {
  display: block;
}
