@import '../../../scss/vars';
@import '../../../scss/mixins';

.stats-panel {
  border-radius: 6px;
  background-color: $white;
  padding: 10px;
  color: $limed-spruce;
  letter-spacing: 0.11px;
  line-height: 20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .stat {
    display: inline-block;
    margin: 0 5px;
    .stat-name {
      font-size: 14px;
      @include bp($tablet-max, 'max') {
        font-size: 12px;
      }
    }
    .stat-value {
      font-size: 17px;
      @include bp($tablet-max, 'max') {
        font-size: 14px;
      }
    }
    .stat-icon {
      cursor: pointer;
      padding-left: 32px;
      @include bp($tablet-max, 'max') {
        padding-left: 16px;
      }
    }
  }
}
