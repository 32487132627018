@import '../../../scss/vars';

.modal {
  padding: 30px;

  :global {
  }

  &>div {
    padding:0;
  }

  .body {
    min-height: 5rem;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .header {
    color: $dark-gray;
    border-bottom: 0;
    h5 {
      font-weight: $font-weight-bold;
    }
  }
  .footer {
    border-top: 0;
    justify-content: flex-start;
  }
}
