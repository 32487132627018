@import "../../../scss/vars";
@import "../../../scss/mixins";

.filter_panel {
  padding-bottom:5px;
  width:100%;

  :global {
    .row {
      div.filter--item {
        &:nth-child(8n) {
          .dropdown-menu {
            @include bp($desktop-min, 'min') {
              left: unset !important;
              right: 0 !important;
            }
          }
        }
      }
    }
    .filter--item{
      margin-bottom:10px;
    }
    .toggle-show {
      text-transform: uppercase;
      cursor: pointer;
    }
    .topActions{
      margin-top: 15px;
      button.dropdown-toggle {
        background-color: $blue;
        border-color: $blue;
      }
    }

    @media (max-width:991px) {
      .filter--item{
        margin-bottom:10px;
      }
    }
  }
}


