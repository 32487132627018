.checkbox {
  display: flex;
  padding: 0;
  input[type="checkbox"] {
    padding: 0;
    margin-right: 5px;
    height: 20px;
    box-sizing: border-box;
  }
  label {
    font-size: 0.9em;
    margin: 0;
  }
}
