@import '../../../scss/vars';

.tabpanel {
  display:block;

  :global {

    .tab-body {
      padding-top: 20px;
      padding-bottom: 15px;
    }
    .nav-tabs {
      .nav{
        &-item{
          margin-bottom: 0;
          margin-right: 30px;
          a.nav-link{
            color: $dodger-blue;
            text-transform: uppercase;
            font-weight: bold;
            cursor: pointer;
            font-size: 12px;
            border: none;
            padding-left: 0;
            padding-bottom: 15px;
            padding-right: 0;

            &.active {
              color: black;
              border-bottom: 6px solid $blue;
              background-color: transparent;
            }
            &.disabled {
              opacity: 0.5;
              cursor: initial;
            }
          }
        }
      }
    }
  }
}
