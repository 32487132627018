@import "../../../scss/vars";
.ui_checkbox {
  position: relative;
  .checkbox {
    display: block;
    margin-bottom: 0;
    padding-left: 23px;
    font-size: 12px;
  }

  input[type=checkbox] {
    display: none;

    /* checkbox aspect */
    &:not(:checked) + .checkbox:before,
    &:checked + .checkbox:before {
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 0;
      background: $white;
      border-radius: 4px;
      box-shadow: none;
      border: 1px solid $checkbox-border;
    }
    /* checked mark aspect */
    &:not(:checked) + .checkbox:after,
    &:checked + .checkbox:after {
      content: '';
      width: 10px;
      height: 5px;
      position: absolute;
      top: 4px;
      left: 3px;
      border: 2px solid $blue;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }
    /* checked mark aspect changes */
    &:checked + .checkbox:after {
      opacity: 1;
    }
    &:not(:checked) + .checkbox:after {
      opacity: 0;
    }
    &:not(:checked) + .checkbox:not(.disabled),
    &:checked + .checkbox:not(.disabled) {
      position: relative;
      cursor: pointer;
    }
    &:checked + .checkbox:not(.disabled) {
      color: $blue;
    }
    &:hover:not(:checked) + .checkbox:not(.disabled):after {
      opacity: 0.5;
    }

    ///* disabled checkbox */
    &:not(:checked) + .checkbox.disabled,
    &:checked + .checkbox.disabled {
      color: $dusty-gray;
    }
    &:checked + .checkbox.disabled:after {
      border-color: $gray;
      color: $dusty-gray;
    }
    &:not(:checked) + .checkbox.disabled:before,
    &:checked + .checkbox.disabled:before {
      box-shadow: none;
      border-color: $silver;
      background-color: $alto;
    }
    .checkbox.disabled {
      color: $gray-chateau;
    }

  }
}