@import '../../../../scss/vars';

.circle {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  padding-left: 0;
  &.fund-manager {
    background-color: $dark-green;
  }
  &.alpha-pipe {
    background-color: $dark-blue;
  }
  &.requestor {
    background-color: $dodger-blue;
  }
}
