@import "../../../scss/vars";

.base_panel {
  background-color: $white;
  padding:1rem;
  border: 10px;
  border-radius: 6px;
}

.table_filter_panel {
  padding-top:20px;
}


