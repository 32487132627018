@import '../../../scss/vars';
@import '../../../scss/mixins';

// the ordinary select dropdown that is used in DataTable Component
:global {
  .alphapipe-select__menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: none !important;
    .alphapipe-select__menu-list {
      padding: 0 !important;
      border: solid $catskill-white !important;
      border-width: 0 1px 1px 1px !important;
      border-radius: 0 0 4px 4px;
      .alphapipe-select__option {
        color: $limed-spruce;
        background-color: $white;
        font-size: 12px;
        @include bp($tablet-max, 'max') {
          font-size: 11px;
        }
        @include bp($tablet-s-max, 'max') {
          font-size: 10px;
        }
        padding: 8px 8px 6px 6px;
        &:hover {
          cursor: pointer;
        }
      }
      .alphapipe-select__option.alphapipe-select__option--is-selected {
        background-color: $option-bgd-is-selected;
      }
      .alphapipe-select__option.alphapipe-select__option--is-selected.alphapipe-select__option--is-focused,
      .alphapipe-select__option.alphapipe-select__option--is-focused {
        background-color: $option-bgd-is-focused;
      }
    }
  }
  .alphapipe-select__control {
    min-height: 32px !important;
    height: 32px;
    border: 1px solid $catskill-white !important;
    box-shadow: none;
    .alphapipe-select__value-container {
      padding: 0 4px;
      .alphapipe-select__single-value {
        padding-top: 2px;
      }
      .alphapipe-select__multi-value {
        background-color: $zumthor;
        border-radius: 2px;
        border: 1px solid $french-passa;
        .alphapipe-select__multi-value__label {
          color: $azure-radiance;
        }
        .alphapipe-select__multi-value__remove {
          color: $azure-radiance;
        }
        .alphapipe-select__multi-value__remove:hover {
          background-color: $hawkes-blue;
          color: $blue-ribbon;
        }
      }
    }
    .alphapipe-select__indicators {
      .alphapipe-select__indicator-separator {
        margin-bottom: 4px;
        margin-top: 4px;
        background-color: $catskill-white;
      }
      .alphapipe-select__indicator.alphapipe-select__clear-indicator,
      .alphapipe-select__indicator.alphapipe-select__dropdown-indicator {
        padding: 4px;
        color: $gray-chateau;
        &:hover {
          color: $alto;
          cursor: pointer;
        }
      }
    }
    &.alphapipe-select__control--is-focused {
      border-color: $catskill-white !important;
      box-shadow: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .alphapipe-select__control:hover {
    border-color: $catskill-white !important;
  }
  .alphapipe-select__control.alphapipe-select__control--menu-is-open {
    border-radius: 4px 4px 0 0;
  }
}

// the select dropdown in material-ui style
:global {
  .material-select__menu {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    box-shadow: none !important;
    .material-select__menu-list {
      padding: 0 !important;
      border: solid $catskill-white !important;
      border-width: 0 1px 1px 1px !important;
      border-radius: 0 0 4px 4px;
      .material-select__option {
        color: $limed-spruce;
        background-color: $white;
        padding: 8px 8px 6px 6px;
        &:hover {
          cursor: pointer;
        }
      }
      .material-select__option.material-select__option--is-selected {
        background-color: $option-bgd-is-selected;
      }
      .material-select__option.material-select__option--is-selected.material-select__option--is-focused,
      .material-select__option.material-select__option--is-focused {
        background-color: $option-bgd-is-focused;
      }
    }
  }
  .material-select__control {
    min-height: 27px !important;
    height: 27px;
    border-radius: unset !important;
    border: none !important;
    border-bottom: 1px solid $alto !important;
    box-shadow: none;
    .material-select__value-container {
      padding: 0 4px;
      .material-select__single-value {
        color: $dodger-blue;
        padding-top: 2px;
      }
    }
    .material-select__indicators {
      .material-select__indicator-separator {
        display: none;
      }
      .material-select__indicator.material-select__clear-indicator,
      .material-select__indicator.material-select__dropdown-indicator {
        padding: 4px;
        color: $dusty-gray;
        &:hover {
          color: $alto;
          cursor: pointer;
        }
      }
    }
    &.material-select__control--is-focused {
      border-color: $alto !important;
      box-shadow: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .material-select__control:hover {
    border-color: $alto !important;
  }
  .material-select__control.material-select__control--menu-is-open {
    border-radius: 4px 4px 0 0;
  }
}