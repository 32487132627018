@import "../../../../scss/vars";
.button_default {
  font-weight: $font-weight-bold;
  background-color: $btn-default;
  border: 0.0625rem solid $btn-default;

  &:hover:not(:disabled):not(.disabled){
    background-color:$btn-default;
    // border: 1px solid white;
  }
}

.button_default_inverse {
  font-weight: $font-weight-bold;
  background-color: transparent;
  color: $btn-primary;
  border: none;
  text-align: left;
  padding-left: 1.5rem !important;
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  &:hover:not(:disabled):not(.disabled) {
    background-color: transparent;
    border: none;
    color: $btn-primary-hovered;
    box-shadow: none;
  }
}