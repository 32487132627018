@import '../../../scss/vars';

$scroll_width: 0.375rem;
$scroll_height: 0.375rem;

:global {
  .add-new-fund-scroll {
    height: 18.125rem;
  }
  .data-table-scroll {
    height: 325px;
  }
  .confirm-publish-requestors {
    max-height: 5rem;
    padding-top: 0.5rem;
  }
  .select-with-checkboxes {
    max-height: 248px;
  }
}

.scrollbar {
  :global {
    .scroll-content {
      min-width: $scroll_width;
      min-height: $scroll_height;
    }
    .scrollbar-track-x, .scrollbar-track-y {
      background-color: $white !important;
    }

    .scrollbar-track-y {
      width: $scroll_width;
    }
    .scrollbar-track-x {
      height: $scroll_height;
    }

    .scrollbar-thumb {
      width: $scroll_width;
      height: $scroll_height;

      border-radius: 6.25rem !important;
      background-color: $dodger-blue-transparent !important;
    }
  }
}
