@import "../../../../scss/vars";
.filter-search {
  :global {
    .col-md-12 {
      padding-left: 0;
      padding-right: 0;
    }
    .input-group {
      height: 2.625rem;
      .input-group-append {
        border: 1px solid $catskill-white;
        background-color: $white;
        border-radius: 0 0.25rem 0.25rem 0;

        .input-group-text {
          background-color: transparent;
          border: none;
          color: $dodger-blue;
        }

        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;

          &:focus {
            outline: none;
          }

          &.disabled {
            box-shadow: initial;
            img {
              cursor: initial;
            }
          }
        }
      }
      .input-group-prepend {
        background-color: $white;
        padding: 10px;
        color: $dusty-gray;
        border-radius: 0.25rem 0 0 0.25rem;
        border: 1px solid $catskill-white;
        align-items: center;
      }

      :local(.input-wrapper) {
        display: flex;
        flex-grow: 1;
      }

      input {
        padding-left: 5px;
        border-left: none;
        z-index: 1;
        &.form-control {
          &:disabled, &[readonly] {
            background-color: $white;
          }
        }
        border-radius: 0;
      }

      /* Chrome, Firefox, Opera, Safari 10.1+ *//* Internet Explorer 10-11 *//* Microsoft Edge */
      ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
        letter-spacing: 0.006875rem;
        color: $oslo-gray;
      }

      ::placeholder {
        opacity: 1; /* Firefox */
      }
    }
  }
}

.dropdown{
  display: none;
  transform: translate(45px, 37px) !important;
  width: 500px;
  min-width: 500px;
  padding: 0;
  border: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  max-height: 300px;
  overflow-y: auto;
}
.dropdown_active {
  display: block;
}

.searchIcon {
  background-color: #fff;
  /* display: flex; */
  /* align-items: center; */
  padding: 10px;
  color: gray;
  /* width: 10px; */
  /* font-weight: lighter; */
  border-radius: 0px 6px 6px 0px;
  border: 1px solid lightgray;
}
