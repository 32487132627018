@import "../../../scss/vars";

.dataset-status {
  margin: 0 0.25rem;
  font-size: 0.75rem;
  letter-spacing: 0;
  color: $dark-gray;
}

.dataset-period {
  font-size: 0.625rem;
  letter-spacing: 0.005625rem;
  color: $white;
  margin-right: 0.25rem;
  width: 2rem;
  height: 1rem;
  display: inline-block;
  text-align: center;
}

.blue-period {
  background-color: $dodger-blue;
}
.yellow-period {
  background-color: $yellow;
}
.green-period {
  background-color: $green;
}
.grey-period {
  background-color: $aqua-haze;
}
.red-period {
  background-color: $red;
}