@import '../../../../scss/vars';

$modal-width: 375px;

.modal {
  padding: 30px;
  min-height: 100vh;
  background-color: $aqua-haze;
  overflow: auto;

  :global {
  }

  &>div {
    padding:0;
  }

  .body {
    min-height: 5rem;
    padding-top: 1rem;
    padding-bottom: 1.75rem;
    height: 70vh;
  }

  .header {
    color: $dark-gray;
    border-bottom: 0;
    height: 32px;
    h5 {
      font-weight: $font-weight-bold;
    }
  }
  .footer {
    border-top: 0;
    justify-content: flex-start;
  }
}

.wrap {

  [role="document"] {
    position: fixed;
    top: 0;
    right: 0;
    margin: 0;
    max-width: $modal-width;
    min-width: $modal-width;
  }
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
/*** Template copied from 'react-accessible-accordion/dist/fancy-example.css' ***/
.u-position-absolute {
  position: absolute;
}

.u-position-relative {
  position: relative;
}

.accordion {
  // border: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 2px;
}

.accordion__item {
  margin: 20px 0;
  box-shadow: 0 0 10px $smooth-black-shadow;
  border-radius: 3px;
  background-color: $white;
  overflow-y: hidden;
}

.accordion__title {
  background-color: $white;
  color: $black;
  cursor: pointer;
  padding: 15px;
  width: 100%;
  text-align: left;
  border: none;
  border-radius: 3px;
  font-size: 0.88em;
  font-weight: $font-weight-bold;

  &:focus {
    outline: none;
  }
}

.accordion__body {
  display: block;
  animation: fadein 0.35s ease-in;
  border-radius: 0 0 3px 3px;
  &.withPadding {
    padding: 7px 20px 20px 20px;
  }
  &.withPaddingWithoutTitle {
    padding: 11px 15px 4px 15px;
    animation: none;
    label {
      margin-bottom: 0;
    }
  }
}

.accordion__body--hidden {
  display: none;
  opacity: 0;
  animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
  margin-bottom: 0;
}

.accordion__arrow {
  display: inline-block;
  position: relative;
  width: 19px;
  height: 5px;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
  display: block;
  position: absolute;
  top: 50%;
  width: 7px;
  height: 2px;
  background-color: $dark-gray;
  content: '';
}

.accordion__arrow::before {
  left: 4px;
  transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
  transform: rotate(-45deg);
}

.accordion__arrow::after {
  right: 4px;
  transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
  transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
  transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

.slideIn {
  -webkit-animation: slideInRight 0.25s ease-out;
  -moz-animation: slideInRight 0.25s ease-out;
  -o-animation: slideInRight 0.25s ease-out;
  animation: slideInRight 0.25s ease-out;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move-down {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes move-up {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
  30% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  0% { transform: translateX($modal-width); }
  100% { transform: translateX(0px); }
}

@keyframes slideOutRight {
  0% { transform: translateX(0px); }
  100% { transform: translateX($modal-width); }
}

.accordion__title--animated:hover .accordion__arrow {
  animation-name: move-down;
  animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
  animation-name: move-up;
  animation-duration: 1.5s;
}

