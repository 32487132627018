@import "../../../scss/vars";
.filter_search {
  :global {
    .input-group {
      flex-wrap: nowrap;
      .input-group-append {
        border: 1px solid $catskill-white;
        border-radius: 0 0.25rem 0.25rem 0;
        button {
          background-color: $catskill-white-light;

          &.disabled {
            box-shadow: initial;
            img {
              cursor: initial;
            }
          }
        }
      }
    }

    input {
      &.form-control {
        &:disabled, &[readonly] {
          background-color: $white;
        }
      }
    }

    /* Chrome, Firefox, Opera, Safari 10.1+ *//* Internet Explorer 10-11 *//* Microsoft Edge */
    ::placeholder, :-ms-input-placeholder, ::-ms-input-placeholder {
      letter-spacing: 0.006875rem;
      color: $oslo-gray;
    }

    ::placeholder {
      opacity: 1; /* Firefox */
    }
  }
}

.dropdown{
  display: none;
  transform: translate(0px, 37px) !important;
  width: 500px;
  min-width: 500px;
  padding: 0;
  border: none;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
}
.dropdown_active {
  display: block;
}
