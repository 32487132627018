@import '../../../scss/_vars.scss';

.progress-area {
  background-color: white;
  margin-top: 12px;
  width: 100%;
  height: 74px;
  border-radius: 6px;
  position: relative;
  padding: 18px 25px;
}

.background-progress {
  height: 14px;
  border-radius: 8px;
  background-color: $dodger-green;
}

.done-progress {
  height: 14px;
  border-radius: 8px;
  background-color: $import-green;
}

.notes {
  display: flex;
  margin-top: 5px;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(50, 60, 71, 0.6);
}
