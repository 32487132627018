@import "../../../scss/vars";
.ui_toggle_switch {
  margin-top: 0.3rem;
  :global {
    label.switch {
      position: relative;
      display: inline-block;
      width: 54px;
      height: 28px;
      margin-bottom: 0;
    }

    .switch input {
      display: none;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $silver;
      -webkit-transition: .4s;
      transition: .4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    input:checked + .slider {
      background-color: $blue;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px $blue;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  .dot-label {
    margin-left: 8px;
    font-weight: $font-weight-regular;
    opacity: 0.5;
    font-size: 12px;
    color: #232425;
    letter-spacing: 0;
    display: inline-block;
    padding-top: 3px;
  }

  .dot-wrapper {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }

  .dots {
    display: flex;
  }

  .title {
    white-space: nowrap;
    font-size: 12px;
    font-weight: $font-weight-bold;
    color: $limed-spruce;
    line-height: 28px;
    padding-top: 2px;
    padding-left: 8px;
    letter-spacing: 0.11px;
    margin-right: 24px;
  }
}

.container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
