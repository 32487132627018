@import '../../../scss/vars';
@import '../../../scss/mixins';

:global(.input-label) {
  &.disabled {
    opacity: 0.65;
  }
  &.blocked {
    span {
      > input {
        background-color: $dark-gray-transparent;
      }
    }
  }
  &.invalid {
    span {
      > input {
        background-color: $red-semi-transparent;
      }
    }
  }
}

:global(.selected-row),
.requestedField {
  background-color: $peach-cream !important;
  :global {
    span, div {
      input {
        border-color: $yellow-half-transparent;
      }
    }
  }
}

.actions-with-buttons {
  width: 22rem;
}

.actions-for-request-manager-screen {
  width: 129px;
  text-align: center;
}

.firm-fund {
  width: 11rem;
  @include bp($tablet-max, 'max') {
    width: 12rem;
  }
  @include bp($tablet-s-max, 'max') {
    width: 11.9rem;
  }
  @include bp($mobile-max, 'max') {
    width: 15rem;
  }
}

.wide-firm-fund {
  width: 20rem;
  @include bp($tablet-max, 'max') {
    width: 20rem;
  }
  @include bp($tablet-s-max, 'max') {
    width: 19rem;
  }
  @include bp($mobile-max, 'max') {
    width: 18rem;
  }
}

.ending-balance {
  width: 5.875rem;
  @include bp($tablet-s-max, 'max') {
    display: none;
  }
}

.perf-month {
  width: 5.875rem;
  @include bp($tablet-max, 'max') {
    width: 6.5rem;
  }
  @include bp($tablet-s-max, 'max') {
    display: none;
  }
}

.last-updated {
  width: 6.5rem;
  @include bp($tablet-max, 'max') {
    display: none;
  }
}

.history {
  width: 15.5rem;
  @include bp($tablet-max, 'max') {
    display: none;
  }
}

.dataset-status {
  width: 11.5rem;
  @include bp($tablet-s-max, 'max') {
    width: 10.5rem;
  }
  @include bp($mobile-max, 'max') {
    display: none;
  }
}

.firm-profile-fund-name {
  width: 17rem;
  @include bp($tablet-max, 'max') {
    width: 17rem;
  }
  @include bp($tablet-s-max, 'max') {
    width: 12rem;
  }
}

.has-no-requests-text, .has-no-access-text, .has-no-datasets {
  @include bp($tablet-s-max, 'max') {
    display: none;
  }
}

.has-no-requests-button, .has-no-access-button, .awaiting-access {
  @include bp($mobile-max, 'max') {
    display: none;
  }
}

th.has-no-datasets,
th.has-no-access-text,
th.has-no-access-button,
th.has-no-requests-button,
th.has-no-requests-text,
th.awaiting-access,
th.firm-profile-no-datasets
{
  display: none;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.with-popover {
  cursor: pointer;
}

.cell {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-width: 0;
  height: 100%;
  .fa {
    margin-bottom: 2px;
    font-size: 12px;
    &.fa_active {
      color: $dodger-blue;
    }
  }
  &.asc {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $mine-shaft-light;
    border-bottom: 5px solid transparent;
  }

  &.desc {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 0 solid transparent;
    border-bottom: 5px solid $mine-shaft-light;
  }
}