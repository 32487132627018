$black: unquote('#000000');
$black-semi-transparent: rgba(0, 0, 0, 0.42);
$blue: unquote('#41A1FF');
$blue-transparent: rgba(65, 161, 255, 0.32);
$blue-transparent-bulk-action-checked: rgba(145, 204, 255, 0.22);
$azure-radiance: unquote('#007BFF');
$dodger-blue: unquote('#4DA1FF');
$dodger-blue-deep: unquote('#1D89F4');
$dodger-blue-transparent: rgba(77, 161, 255, 0.36);
$light-blue: unquote('#5C9BE4');
$super-light-blue: unquote('#F0F8FF');
$super-dark-blue: unquote('#2E4B5F');
$limed-spruce: unquote('#323C47');
$limed-spruce-transparent: rgba(50, 60, 71, 0.5);
$dark-gray: unquote('#3F474C');
$dark-gray-transparent: rgba(63, 71, 76, 0.2);
$oslo-gray: unquote('#8C9194');
$grey: unquote('#9C9C9C');
$catskill-white: unquote('#E9EFF4');
$catskill-white-light: unquote('#FBFCFD');
$gallery: unquote('#EAEAEA');
$uploadMessage: unquote('#E2EEF9');
$uploadMessageBorder: unquote('#9CC3EF');
$manatee: unquote('#989DA3');
$dusty-gray: unquote('#9B9B9B');
$dove-gray: unquote('#666666');
$athens-gray: unquote('#E4E6E9');
$alto: unquote('#D8D8D8');
$gray-chateau: unquote('#A7ABAF');
$timberwolf: unquote('#D3D0CB');
$gray-chateau-cold: unquote('#A5ABB1');
$astral: unquote('#3876B0');
$mischka: unquote('#CFD4DB');
$trout: unquote('#495057');
$malibu: unquote('#80BDFF');
$malibu-dark: unquote('#58A6FE');
$iron: unquote('#D4D9DC');
$mine-shaft: unquote('#222222');
$mine-shaft-light: unquote('#333333');
$delimiter-gray: unquote('#E0E0E0');
$aqua-haze: unquote('#F4F8F9');
$aqua-haze-transparent: rgba(244, 248, 249, 0.52);
$rose-white: unquote('#FFFDFD');
$porcelain: unquote('#EDF1F2');
$silver: unquote('#CCCCCC');
$gray: unquote('#888888');
$white: unquote('#FFFFFF');
$white-shadow: rgba(255,255,255, 0.02);
$gray-dark: unquote('#ced4da');
$gray-super-dark: unquote('#969899;');
$green: unquote('#21d368');
$green-transparent: rgba(33, 211, 104, 0.08);
$dodger-green: unquote('#DEF9E8');
$import-green: unquote('#24D161');
$week-green: unquote('#DEF3E9');
$blacky-green: unquote('#277F61');
$dark-green: unquote('#26A96B');
$red: unquote('#FF3C54');
$red-light-transparent: rgba(255, 60, 84, 0.19);
$red-transparent: rgba(255, 60, 84, 0.1);
$red-semi-transparent: rgba(255, 60, 84, 0.53);
$red-border: unquote('#E7A3AA');
$red-froly: unquote('#EF6F7E');
$dodger-red: unquote('#FFF1F3');
$yellow: unquote('#f5a623');
$yellow-transparent: rgba(245, 166, 35, 0.07);
$yellow-half-transparent: rgba(245, 166, 35, 0.5);
$peach-cream: unquote('#FFF2DC');
$blacky-beauty: unquote('#2C4B60');
$checkbox-border: rgba(63, 71, 76, 0.21);
$notifications-circle: unquote('#F7A70A');
$river-bed: unquote('#4E5D66');
$half-spanish-white: unquote('#FEF2DE');
$gainsboro: unquote('#dcdcdc');
$jungle-green: unquote('#27A96A');
$boulder: unquote('#7D7D7D');
$alizarin-crimson: unquote('#E81D36');
$froly: unquote('#EF6E7E');
$shark: unquote('#232425');
$dodger-blue: unquote('#489FFF');
$hawkes-blue: unquote('#D5E7FB');
$progress-blue: unquote('#E3EFFB');
$havelock-blue: unquote('#4A90E2');
$dark-blue: unquote('#357bab');
$tundora: unquote('#4A4A4A');
$black-shadow: rgba(0, 0, 0, 0.2);
$smooth-black-shadow: rgba(0, 0, 0, 0.05);
$search-attribute-type: rgba(0, 0, 0, 0.5);
$monza: unquote('#D0021B');
$zumthor: unquote('#EBF5FF');
$link-water: unquote('#DCE8F4');
$alice-blue: unquote('#F5FAFF');
$french-passa: unquote('#C2E0FF');
$blue-ribbon: unquote('#0071e6');
$zircon: unquote('#E4E7E7');

//buttons
$btn-primary: $dodger-blue;
$btn-primary-hovered: $dodger-blue;
$btn-success: $green;
$btn-success-transparent: $green-transparent;
$btn-danger: $red;
$btn-default: $white;

$header-height: 64px;
$panel-width: 94px;
$panel-image-height: 24px;

// select dropdown
$option-bgd: $catskill-white;
$option-bgd-is-focused: $zumthor;
$option-bgd-is-selected: $alice-blue;

// fonts
$sailec-font: Sailec;
$main-font: $sailec-font;
$icon-font: 'alpha-font';

// font weights
$font-weight-black: 800;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-regular: 400;
$font-weight-light: 300;
$font-weight-thin: 200;
$font-weight-hairline: 100;

// request status
$status-new: unquote('#DA70D6');
$status-open: unquote('#DAA520');
$status-overdue: unquote('#FF0000');
$status-rescinded: unquote('#3379CC');
$status-closed: unquote('#4AAB33');
$status-reopened: unquote('#4B0082');

// popover
$popover-header: unquote('#7D888D');
$popover-menu-border: rgba(0, 0, 0, 0.1);

// date filter
$date-day: rgba(255, 255, 255, 0.85);
$date-day-number: unquote('#1D2429');
$date-display-background-color: rgba(239, 242, 247, 1.0);
$date-display-item: unquote('#849095');
$date-display-item-active: $popover-header;
$date-display-item-shadow: rgba(35, 57, 66, 0.21);
$date-year-picker-select: unquote('#3E484F');
$date-month-and-year-hover-background: rgba(0,0,0,0.07);
$date-arrow-color: unquote('#EFF2F7');
$date-arrow-hover-color: unquote('#E1E7F0');
$date-arrow-border: rgba(52, 73, 94, 1.0);
$date-week-day: rgba(132, 144, 149, 1.0);
$date-today: unquote('#3D91FF');
$date-day-preview: rgba(255, 255, 255, 0.09);
$date-input-range: rgba(108, 118, 122, 1.0);
$date-input-range-border: rgba(222, 231, 235, 1.0);
$date-input-range-border-focus: rgba(180, 191, 196, 1.0);
$date-day-number-passive: unquote('#D5DCE0');
$date-day-disabled-background: unquote('#F8F8F8');
$date-day-disabled-number: unquote('#AEB9BF');

