@import "../../../scss/vars";
.ui_loader {
  border: 16px solid $aqua-haze;
  border-radius: 50%;
  border-top: 16px solid $blue;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  :global {
    /* Safari */
    @-webkit-keyframes spin {
      0% { -webkit-transform: rotate(0deg); }
      100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}

.ui_loader_xs {
  border: 3px solid $aqua-haze;
  border-radius: 50%;
  border-top: 3px solid $blue;
  width: 30px;
  height: 30px;
}