// Use the following new breakpoints:
// mobile small: 576px and narrower
// mobile: 576px - 768px,
// tablet small: 768px - 992px,
// tablet: 992px - 1200px,
// desktop: 1200px' and wider,

//resolutions
$mobile-s-min:  320px;
$mobile-s-max:  575.98px;

$mobile-min:  576px;
$mobile-max:  767.98px;

$tablet-s-min:  768px;
$tablet-s-max:  991.98px;

$tablet-min:  992px;
$tablet-max:  1199.98px;

$desktop-min: 1200px;

// breakpoint mixin
// default usage example: @include bp($mobile-max, 'max') {}
// usage example 2: @include bp($tablet-min, 'min') {}
// usage example 3: @include bp('500px', "max") {}
@mixin bp($point, $query1: min, $query2: width) {
  @media only screen and (#{$query1}-#{$query2}: $point) {
    @content
  }
}
