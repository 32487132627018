@import "../../../scss/vars";

.filterWithCheckboxes {
  :global {
    .btn-secondary:not(:disabled):not(.disabled):active {
      color: $dusty-gray;
      border-color: $dusty-gray;
    }
    .btn-secondary.disabled, .btn-secondary:disabled {
      border-bottom: 1px dashed $alto;
      background-color: transparent;
      color: $dusty-gray;
    }
    .dropdown-menu {
      transform: none!important;
      width: 320px;
      border: none;
      border-radius: 6px;
      -webkit-box-shadow: 0 2px 4px 2px $dark-gray-transparent;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
      -moz-box-shadow: 0 2px 4px 2px $dark-gray-transparent;  /* Firefox 3.5 - 3.6 */
      box-shadow: 0 2px 4px 2px $dark-gray-transparent;
      button {
        &:hover {
          cursor: pointer;
          background-color: $gainsboro;
        }
      }
    }
  }
}

.dropHeader {
  padding: 20px 25px 10px;
}

.filterTitle {
  font-size: 12px;
  width: 100%;
  letter-spacing: 0.1px;
  color: $dusty-gray;
  background-color: transparent;
  border: none;
  border-radius: inherit;
  padding: 0.375rem 0 0;
  border-bottom: 1px solid $alto;
  -webkit-transition : border 700ms ease-out;
  -moz-transition : border 700ms ease-out;
  -o-transition : border 700ms ease-out;
  transition : border 700ms ease-out;
  &:hover, &:active, &:focus, &:visited {
    background-color: transparent !important;
    border-color: $dusty-gray;
    color: $dusty-gray;
    box-shadow: none !important;
    i {
      border-color: $dusty-gray;
    }
  }
  div {
    float: left;
    font-size: 14px;
    width: calc(100% - 24px);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  i {
    float: right;
    border: solid $alto;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 3px;
    margin: 3px 6px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -webkit-transition : border 700ms ease-out;
    -moz-transition : border 700ms ease-out;
    -o-transition : border 700ms ease-out;
    transition : border 700ms ease-out;
  }
}

.sortButtons {
  margin-bottom: 25px;
}

.searchLine {
  margin-bottom: 15px;
  padding: 6px 0 2px 6px;
}

.selectChoicesList {
  margin-right: -17px;
}

.label {
  color: $dusty-gray;
  font-size: 0.625rem;
  font-weight: $font-weight-medium;
  margin-bottom: 5px;
  text-align: left;
}

.activeChoicesTitle {
  color: $dodger-blue;
}

.checkbox-wrapper {
  max-width: 270px;
  label {
    margin-left: 10px;
  }
}
