@import "../../../scss/vars";

.niceBody {
  border-top: none !important;
  :global {
    tr {
      &:first-child {
        td {
          border-top-color: white !important;
        }
      }
    }
  }
}

.tr {
  :global {
    td {
      background-color: $super-dark-blue !important;
      color: white;
      position: relative;
    }
  }
}

.pendingLink {
  text-decoration: underline;
  margin-left: 8px;
}

.actionsRow {
  :global {
    span:not(.hidden-action) {
      display: flex;
      justify-content: flex-end;
      margin-right: 10px;
    }
    span.hidden-action {
      margin-right: 10px;
    }
  }
}