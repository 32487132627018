@import "./vars";

:global {
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icon-font !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: normal;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-add-user:before {
    content: "\e900";
  }
  .icon-breach:before {
    content: "\e901";
  }
  .icon-dashboard:before {
    content: "\e902";
  }
  .icon-data-manager:before {
    content: "\e903";
  }
  .icon-firm:before {
    content: "\e904";
  }
  .icon-fund:before {
    content: "\e905";
  }
  .icon-portfolio:before {
    content: "\e906";
  }
  .icon-requests:before {
    content: "\e907";
  }
  .icon-profiles:before {
    content: "\e908";
  }
  .icon-discard:before {
    content: "\e909";
  }
  .icon-unlocked:before {
    content: "\e90a";
  }
  .icon-locked:before {
    content: "\e90b";
  }
  .icon-gear:before {
    content: "\e90c";
  }
  .icon-slider-handler:before {
    content: "\e90d";
  }
  .icon-search:before {
    content: "\e90e";
  }
  .icon-settings:before {
    content: "\e90f";
    color: $havelock-blue;
  }
  .icon-update-in-db:before {
    content: "\e910";
  }
}
