@import "../../../scss/vars";

.dataset-row {
  border-top: none !important;
  .dataset-history-cell {
    height: 64px;
    text-align: right;
    padding-right: 5px;
    padding-left: 5px;
  }
  :global {
    td {
      background-color: $super-dark-blue !important;
      border-top: 0.0625rem solid $river-bed !important;
      color: white;
      position: relative;
    }
  }
}
:global {
  tbody:local(.dataset-row) {
    &:last-child {
      td {
        &:first-child {
          border-radius: 0 0 0 6px;
        }
      }
    }
  }

  .j-body_items + tbody tr td:local(.dataset-history-cell):first-child {
    border-top: none !important;
    &:before {
      content: '';
      top: 0;
      left: 80%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: $blue;
      border-width: 19px 35px;
      margin-left: -56px;
    }
  }
}

