@import '../../../../scss/vars';

.alert-default {
  background: $yellow-transparent;
  border: 0.0625rem solid $yellow;
  border-radius: 0.375rem;
  position: relative;
  font-size: 0.8125rem;
  color: $yellow;
  letter-spacing: 0.0075rem;
  line-height: 1.25rem;
  text-align: center;

  padding: 1.25rem 2rem;
}

.alert-default-inverse {
  background: $white;
  border: 0.0625rem solid $yellow;
  border-radius: 0.375rem;

  font-size: 0.8125rem;
  color: $yellow;
  letter-spacing: 0.0075rem;
  line-height: 1.25rem;
  text-align: center;

  padding: 1.25rem 2rem;
}

.closer {
  position: absolute;
  right: 2rem;
  top: 1.25rem;
  cursor: pointer;
}

.closerIcon {
  width: 1rem;
  height: 1rem;
}