@import '../../../../scss/vars';

.dropdown_default {
  :global{

    button.dropdown-toggle {
      font-weight: $font-weight-medium;
      letter-spacing: 0.6px;
      background-color: transparent;
      color: $dodger-blue;
      border: 0.0625rem solid $dodger-blue;
      min-width: 130px;
      padding: 0.5rem 1.625rem 0.25rem;
      font-size: 0.75rem;
      border-radius: 0.25rem;
      text-transform: uppercase;

      &:after {
        content: none;
      }
    }

    .dropdown{
      &-item{
        padding: 0.25rem 1rem;
        cursor: pointer;

        &.active, &:active {
          color: $white;
          text-decoration: none;
          background-color: $blue;
        }
        &, a{
          color:black;
        }
      }
      &-menu{
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
      }
    }

  }
}