@import '../../../scss/_vars.scss';

.progress-area {
  background-color: $blacky-beauty;
  position: relative;
  width: 100%;
  height: 45px;
  border-bottom: none;
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  :global {
    div.text-div {
      padding: 0 1rem;
      &:first-child {
        width: 25%;
        text-align: right;
      }
    }
  }
}

.background-progress {
  width: 70%;
  height: 14px;
  border-radius: 8px;
  background-color: $blacky-green;
}

.done-progress {
  height: 14px;
  border-radius: 8px;
  background-color: $import-green;
}

.img-close {
  margin: 0 1.35rem 0 0.5rem;
  width: 0.75rem;
  cursor: pointer;
}

.img-cancel {
  margin: 0 1rem;
  width: 1rem;
  cursor: pointer;
}

.completed-process-text {
  flex: 2;
  text-align: center;
}

.fail-message {
  color: $red;
}

.success-message {
  color: $green;
}

.danger-message {
  color: $red;
}

.link-to-file {
  padding: 0 4px;
  &:hover {
    color: $dodger-blue;

  }
}