@import "../../scss/vars";

.wrapper {
  td {
    vertical-align: middle;
  }
  .buttons {
    margin-bottom: 20px;
  }
}

.dataset_configuration_modal {
  :global {
    .filter--item {
      &.right{
        padding-right:22px;
      }
      &.left {
        padding-left:22px;
      }
      padding-top: 10px;
    }

      .modal-body {

        padding:0;
      }
  }
}

.dataset {
    padding: 0;

  :global {

    td {
      padding-left:0;
      padding-right:0;
    }
    th {
      padding-right: 0!important;
    }
    .react-numeric-input input {
      width: 100%;
      padding: 5px;
      text-align: right;
      float: right;
    }
    .dataset-nav,  .dataset-performance, .triangledBlueTd, .version, .oos-response-date {
      padding-right: 5px;
      padding-left: 5px;
    }
    .dataset-nav {
      width: 100%;
    }
    .dataset-performance {
      width: 70%;
    }

    .oos-response-date {
      width: 7.5rem;
      input.DateInput_input {
        padding: 0 24px 0 2px;
        font-size: 0.75rem;
      }
      .SingleDatePickerInput .SingleDatePickerInput_calendarIcon {
        bottom: 3px;
        img {
          width: 14px;
        }
      }
    }

    .version {
      width: 7.5rem;
    }
    .last-updated-at {
      width: 130px;
    }
    .data-source {
      width: 9rem;
    }

    .b-panel-center {
      position: relative;
      top: 3px;
    }
  }
}

.dataset_configuration_modal {
  :global {
    .nav-tabs {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
    }
    .ui-filter-panel {
      padding-bottom: 0;
    }
    .filter--item{
      h5.label {
        margin-bottom: 0;
      }
    }
  }
}

.pendingLink {
  text-decoration: underline;
  margin-left: 0.5rem;
}

.rowModified {
  margin: 0;
}