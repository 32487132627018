@import "../../../../scss/vars";
.input {
  border: none;
  border-bottom: 1px solid $black-shadow;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.9em;
  border-radius: 0;
  &:global(.form-control) {
    &:focus {
      box-shadow: none;
    }
  }
}
