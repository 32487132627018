@import "../../scss/vars";

.userCircle {
  float: right;
  cursor: pointer;
  background-color: $dodger-blue;
  color: white;
  font-size: 12px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.userShortName {
  margin-top: 2px;
  letter-spacing: 0.5px;
}