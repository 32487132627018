@import "../../scss/vars";

.notifications {
  cursor: pointer;
  float: right;
  color: $dodger-blue;
  margin: 21px 40px 0 8px;
  font-size: 12px;
}

.circle {
  cursor: pointer;
  float: right;
  background-color: $notifications-circle;
  width: 9px;
  margin: 24px 0 0;
  height: 9px;
  border-radius: 5px;
  position: absolute;
  top: -25px;
  left: 15px;
}

.circleWrapper {
  float: right;
  margin-top: 18px;
  margin-right: 2px;
  position: relative;
}