@import '../../../scss/vars';

.tableGrid {
  &:first-child {
    margin-bottom: 0;
  }
  :global {
    thead {
      tr {
        th {
          width: 22%;
          font-size: 10px;
          font-weight: $font-weight-medium;
          color: $limed-spruce-transparent;
          border: none;
          &:nth-child(3) {
            width: 34%;
          }
          span {
            font-size: 10px;
            color: $limed-spruce-transparent;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 5px 12px 7px;
          border: none;
          width: 22%;
          font-size: 12px;
          &:nth-child(3) {
            width: 34%;
          }
        }
      }
    }
    .action-items a {
      margin-right: 14px;
      img {
        width: 16px !important;
      }
    }
  }
}

.tableIcon {
  margin: -0.25rem 0 0 0.625rem;
}

.actionGroup {
  display: flex;
  justify-content: flex-start;
  .action {
    display: inline-block;
    padding-right: 10px;
  }
}
