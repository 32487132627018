@import './scss/clear';
@import './scss/vars';
@import './scss/fontIcons';
body {
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  font-family: $main-font;
  font-weight: $font-weight-regular;
  font-style: normal;
}

:global {
  .table tbody + tbody {
    border-top: none;
  }

  h1, h2, h3 {
    color: $dark-gray;
  }

  h5.label {
    font-size: 0.625rem;
    color: $gray-super-dark;
    margin-bottom:10px;
  }

  a {
    color: $dodger-blue;
    &:hover {
      color: $dodger-blue;
    }
  }

  .f-link {
    cursor: pointer;
    color: $dodger-blue;
    &:hover {
      text-decoration: underline;
    }
  }

  .gray-wrapper {
    flex:1;
    background-color: $aqua-haze;
  }

  .wrapper{
    &-bottom{
      margin-bottom: 15px;
    }
  }

  .icon-tooltip{
    position: absolute;
    margin-left: -24px;
    margin-top: -2px;
    cursor: pointer;
  }

  .width-line-style{
    padding-left: 15px;
    padding-right: 15px;
  }

  .j-fixed {
    position: fixed;
    top: 0;
    box-shadow: 0 4px 5px -5px grey;
    background: $aqua-haze;
    z-index: 100;
    padding-top: 20px;
  }

  .table-text {
    &--center{
      text-align:center!important;
    }
    &--vertical-middle {
      td, th {
        vertical-align: middle!important;
      }
    }
  }

  .comment{
    font-size:12px;
  }

  .prelined {
    white-space: pre;
  }

  .active-item {
    background: $aqua-haze;
  }

  .button-item {
    float:right;
    margin-left:5px;
  }

  .loader {
    &-center {
      margin: 0 auto;
    }

    &-left {
      float:left;
    }

    &-right {
      float: right;
    }
  }

  .ui{
    &-pl-5{
      padding-left: 5px;
    }
    &-inlineBlock {
      display: inline-block;
    }
    &-button{
      &--clear {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
    &-tooltip{
      span.arrow{
        border: 0;
        &::before{
          content: ' ';
          border-bottom-color: black;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid $white;
        }
      }

      .tooltip-inner{
        font-size: 12px;
        color:black;
        border: 1px solid black;
        background: $white;
      }
      &--danger {
        div.tooltip {
          &-inner{
            color: $red;border: 1px solid $red;
          }
        }
        span.arrow{
          &::before {
            content: '';
            border-bottom-color: $red;
            border-top: 5px solid $red;
          }
        }
      }

      &--primary {
        div.tooltip {
          &-inner{
            border: 1px solid $blue;
            color: $blue;
          }
        }
        span.arrow{
          &::before {
            content: '';
            border-bottom-color: $blue;
            border-top: 5px solid $blue;
          }
        }
      }

      &--success {
        div.tooltip-inner {color: $green;border: 1px solid $green;  }
      }
    }
  }


  .ui-table.ap-data-table {

    & th, & td {
      padding: 10px 15px;
      word-wrap: break-word;
    }
    & th {
      line-height: initial;
    }
  }

  .ui-modal  {
    &--confirm {
      .modal-body {
        margin-top: 15px;
      }
    }
    & .modal-body {
      min-height:400px;
    }
    &--same-height {
      .modal-body, .modal-footer {
        padding-top: 0;
      }

      .modal-footer .modal-buttons {
        margin-top:10px;
      }
    }

  }

  .modal-md-2 {
    max-width: 600px;
    min-width: 600px;
    width: 600px;

    @media (min-width: 576px) {
      &.modal-dialog {
        max-width: 600px;
        min-width: 600px;
        width: 600px;
      }
    }
  }

  .modal-footer{
    justify-content: flex-start;
  }

  .ui-material {
    .DateInput {
      margin-left: 0;
      border-right: 0;
      border: none;
      &:first-child {
        margin-right: 20px;
      }
    }

    .DateRangePickerInput_arrow{
      display: none;
    }

  }

  .text {
    &-primary {
      color: $btn-primary !important;
    }
    &-danger {
      color: $btn-danger !important;
    }
  }

  .ui-circle {
    text-align: center;
    flex: 1 1 auto;
    .circle-item {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      &, &--value{
        width: 62px;
      }

      &--value {
        height: 62px;
        font-size: 2rem;
        color:$white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-radius: 50%;
        padding-top: 0.375rem;
      }
      &--label{
        font-size:0.7rem;
        padding: 5px 0;
      }
    }
  }

  .ui-btn{
    min-width: 130px;
    padding: 0.5rem 1.625rem 0.25rem;
    font-size: 0.75rem;
    border: 0.0625rem solid $white;
    border-radius: 0.25rem;
    text-transform: uppercase;
    &.ui-btn-lg {
      height: 46px;
      width: 230px;
      // padding: 1.125rem 4.375rem 1rem;
    }
    &:hover:not(:disabled):not(.disabled){
      opacity: 0.9;
    }
    &:focus, &:active {
      box-shadow: none;
    }
    &.disabled {
      border: 0.0625rem solid $grey;
      background: $grey;
    }
  }

  .popover {
    .arrow {
      border: none;
      padding: initial;
    }
  }

  .arrow {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -8px;

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    &.primary {
      border: solid $blue;
      border-width: 0 1px 1px 0;
    }
  }

  .publ-datasets-show-hide-modal-wrapper {
    margin-top: -28px;
    margin-bottom: -14px;
  }

  .aligned-checkboxes {
    padding-bottom: 6px;
    label {
      margin-left: 0;
    }
  }

  .block-title {
    font-size: 10px;
    color: $dusty-gray;
    padding: 14px 0 15px;
    letter-spacing: 0.1px;
  }

  .clear-button {
    background: none;
    border: none;
    box-shadow: none;
    outline:none!important;
  }

  .titleNav {
    font-size: 14px;
    font-weight: $font-weight-bold;
    color: $gray-super-dark;
  }

  .CurrencyLabel {
    font-size: 14px;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding-left: 5px;
    color: $blue;
  }

  .entity--items li.entity--code {
    display: inline-block;
    padding-left: 5px;
  }

  .ap-breadcrumbs {
    font-weight: $font-weight-regular;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
  }

  .breadcrumb-slash {
    padding: 0 3px;
  }

  .ui-table div div.ui-table--responsive tbody tr td.white-empty-row {
    border-top: none;
    height: 65px;
  }

  .home-pending-requests,
  .home-my-funds,
  .home-diligence-requests {
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
    font-weight: $font-weight-bold;
    color: $dark-gray;
    letter-spacing: -.0325rem;
    padding: 0 0 0 6px;
    div {
      &:first-child {
        margin: 0.5rem 0;
        color: $dark-gray;
      }
      a {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: bold;
        cursor: pointer;
      }
    }
  }

  .home-my-funds,
  .home-diligence-requests {
    margin-top: 40px;
  }

  .home-pending-requests + div.ui-table {
    div.j-filter {
      &:first-child {
        padding-top: 0;
      }
      div.row {
        display: none;
      }
    }
    div.ui-table--responsive {
      max-height: 325px;
    }
  }

  .home-my-funds + div {
    div.j-filter {
      &:first-child {
        padding-top: 0;
      }
      div.row {
        display: none;
      }
    }
    div.ui-table--responsive {
      height: 325px;
    }
  }

  .home-diligence-requests + div {
    div.j-filter {
      &:first-child {
        padding-top: 0;
      }
      div.row {
        display: none;
      }
    }
    div.ui-table--responsive {
      max-height: 325px;
    }
  }

  .dataset-actions {
    button.btn.btn-secondary.dropdown-toggle {
      &:focus,
      &:active {
        background-color: $dodger-blue;
        border: 1px solid $dodger-blue;
        box-shadow: none;
        color: white;
      }
    }
  }

  .show.dataset-actions {
    button.btn.btn-secondary.dropdown-toggle {
        background-color: $dodger-blue;
        border: 1px solid $dodger-blue;
        box-shadow: none;
        color: white;
    }
    div.dropdown-menu.show {
      margin: 0;
      padding: 0;
      top: 5px !important;
      left: -27px !important;
      box-shadow: none;
      font-size: 14px;
      button {
        padding: 0.6rem 1rem 0.4rem;
        font-weight: $font-weight-light;
        &:focus {
          box-shadow: none;
          outline: none;
        }
      }
      .dropdown-divider {
        margin: 0;
      }
    }
  }

  .eighties {
    width: 80%;
  }

  .requested-item-wrapper {
    position: relative;
    cursor: pointer;
    .requested-item {
      font-weight: bold;
      color: $yellow;
      position: relative;
    }
  }

  .popover {
    border: 1px solid $blue;
    .popover-body {
      color: $blue;
    }
    .arrow::before {
      border-top-color: $blue;
      border-bottom-color: $blue;
    }
  }

  .requested-item-popover.popover {
    border: 1px solid $yellow;
    color: $limed-spruce;
    .popover-body {
      text-align: center;
      font-size: 0.75rem;
      letter-spacing: 0.006875rem;
      line-height: 1.25rem;
    }
    .arrow::before {
      border-top-color: $yellow;
      border-bottom-color: $yellow;
    }
  }

  .clear-padding {
    padding: 0 !important;
  }

  .nanobar {
    .bar {
      background-color: $blue;
    }
  }

  .w {
    &-15 {
      width: 15px;
    }
    &-20 {
      width: 20px;
    }
    &-23 {
      width: 23px;
    }
    &-24 {
      width: 24px;
    }
  }

  .navRow .row {
    position: relative;
    overflow: hidden;
    margin: 0 5px 0 20px;

    &:after, &:before {
      content : "";
      z-index: 100;
      width : 1px;
      height : 7px;
      background: $iron;
      position: absolute;
      top: 6px;
    }

    &:after {
      right : 0;
    }

    &:before {
      left : 0;
    }

    button {
      vertical-align: baseline;
      zoom: 1;
      display: inline;
      position: relative;
      padding: 0 20px;
      margin: 0 auto;

      &:before, &:after {
        content: '';
        display: block;
        width: 750px;
        position: absolute;
        top: 0.73em;
        border-top: 1px solid $iron;
      }

      &:before { right: 100%; }
      &:after { left: 100%; }
    }
  }

  .j-body_items + tbody tr td.triangleTd {
    &:before {
      top: 0;
      left: 80%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid rgba(136, 183, 213, 0);
      border-top-color: white;
      border-width: 16px 27px;
      margin-left: -30px;
    }
  }

  .j-body_items + tbody tr td.triangleTdColspan2 {
    &:before {
      top: 0;
      left: calc(80% - 103px);
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: solid rgba(136, 183, 213, 0);
      border-top-color: white;
      border-width: 16px 27px;
      margin-left: -30px;
    }
  }

  .j-body_items + tbody tr td.triangledBlueTd:first-child {
    border-top: none !important;
    &:before {
      content: '';
      top: 0;
      left: 80%;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-top-color: $blue;
      border-width: 19px 35px;
      margin-left: -56px;
    }
  }
}