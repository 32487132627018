@import '../../../scss/vars';

.singleCheckboxFilter {
  &.disabled {
    opacity: 0.65;
  }
  label span {
    font-size: 14px;
  }
  margin-top: 22px;
}
