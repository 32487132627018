@import '../../../scss/vars';

.role-selector {
  margin-top: -1rem;
}

.role-select-dropdown {
  font-size: 14px;
  :global {
    .material-select__control {
      min-height: 30px !important;
      height: 32px;
    }
    .material-select__value-container {
      padding-left: 0;
      .material-select__single-value {
        color: $dove-gray;
        padding-left: 0;
        top: 60%;
      }
      .material-select__placeholder {
        top: 60%;
        color: $alto;
      }
    }
  }
}
